<div *ngIf="events.length > 0" class="events">
    <h3>Agenda</h3>

  <div class='events_no-swiper' *ngIf="shouldDisplaySimpleEvents(); else swiperBlock">
    <!-- Affichage simple des événements sans swiper -->
    <div class='event_no-swiper' *ngFor="let event of events">
      <app-event [vnfEvent]="event"></app-event>
    </div>
  </div>

  <ng-template #swiperBlock>
    <!-- Swiper avec direction dynamique -->
    <swiper-container *ngIf="swiperDirection" #swiperContainer class="swiper-container"
        [ngClass]="{'vertical': swiperDirection === 'vertical', 'horizontal': swiperDirection === 'horizontal'}"
        init="false">

        <!-- Si swiperDirection est 'vertical', on groupe les événements -->
        <ng-container *ngIf="swiperDirection === 'vertical'; else horizontalTemplate">
            <swiper-slide *ngFor="let group of groupedEvents" class="swiper-item">
                <app-event *ngFor="let event of group" class="event" [vnfEvent]="event" [graphicType]="swiperDirection">
                </app-event>
            </swiper-slide>
        </ng-container>

        <!-- Template pour swiperDirection 'horizontal' -->
        <ng-template #horizontalTemplate>
            <swiper-slide *ngFor="let event of events" class="swiper-item">
                <app-event class="event" [vnfEvent]="event" [graphicType]="swiperDirection">
                </app-event>
            </swiper-slide>
        </ng-template>

    </swiper-container>
  </ng-template>
</div>
