<div class="on-pictures">
  <p class="on-pictures__title">En images</p>

  <div class="on-pictures__no-slider" *ngIf="shouldDisplaySimplePictures(); else swiperBlock">
    @for (media of enImages; track media.id) {
    <div class="on-pictures__no-slider--item">
      <a href="javascript:void(0);" role="button" (click)="openDialogMedia(media)"
         class="on-pictures__card-link">Accéder</a>
      <div class="on-pictures__card">
        <div class="on-pictures__card-img">
          <img src="{{media.visuel.sizes?.['w255h145']}}" width="255" height="145" alt="{{media.visuel.alt}}">
          <div class="news-secondary-tags-container">
            <span *ngIf="media.dt" class="news-secondary-tag">DT {{media.dt}}</span>
            <span *ngIf="media.perimetre === 'Siège'" class="news-secondary-tag">{{media.perimetre}}</span>
          </div>
        </div>

        <div class="on-pictures__card-content {{media.typeMedia == 'video'? 'video' : ''}}">
          <p [innerHTML]="media.title"></p>
        </div>
      </div>
    </div>
    }
  </div>

  <ng-template #swiperBlock>
  <div class="on-pictures__slider">
    <swiper-container #swiperContainer class="swiper-container" init="false">
      @for (media of enImages; track media.id) {
        <swiper-slide>
          <a href="javascript:void(0);" role="button" (click)="openDialogMedia(media)"
            class="on-pictures__card-link">Accéder</a>
          <div class="on-pictures__card">
            <div class="on-pictures__card-img">
              <img src="{{media.visuel.sizes?.['w255h145']}}" width="255" height="145" alt="{{media.visuel.alt}}">
              <div class="news-secondary-tags-container">
                    <span *ngIf="media.dt" class="news-secondary-tag">DT {{media.dt}}</span>
                    <span *ngIf="media.perimetre === 'Siège'" class="news-secondary-tag">{{media.perimetre}}</span>
              </div>
            </div>

            <div class="on-pictures__card-content {{media.typeMedia == 'video'? 'video' : ''}}">
              <p [innerHTML]="media.title"></p>
            </div>
          </div>
        </swiper-slide>
      }

    </swiper-container>
    <div class="swiper-navigation">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
  </ng-template>
</div>
