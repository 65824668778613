<div class="search-results">
    <div class="search-results__list">
        @if (isLoading && documents.length === 0) {
            <app-search-document *ngFor="let _ of getPerPageArray(); let i = index"
                                 [document]="fakeDocument"
                                 [isLoading]="isLoading">
            </app-search-document>
        } @else {
            <app-search-document *ngFor="let document of documents; let i = index"
                                 [document]="document"
                                 [isLoading]="isLoading">
            </app-search-document>
        }
    </div>
</div>
