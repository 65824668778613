// configuration d'preproduction (Azure)
export const environment = {
  production: false,
  enableLogging: false,
  cacheExpiration: 3600, //seconds
  apiUrl: 'https://vnfetmoi-api-prep2.vnf.fr/wp-json',
  msalConfig: {
    // Configuration Azure VNF INT
    auth: {
      clientId: '6b7c7606-e66c-4e26-8753-cbae49c7f3ef',
      authority: 'https://login.microsoftonline.com/e656fd6a-aaca-470b-a737-8d19eb341c97/v2.0',
    },
  },
  azureApiConfig: {
    scopes: ['User.Read', 'User.Read.All', 'profile', 'openid', 'Calendars.readWrite'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  elasticSearchConfig: {
    documentDetail: false,
  },
  piwikProConfig: {
    id: '22c09dc8-8747-4541-a219-cbbc24d2db8d',
    url: 'https://vnf.piwik.pro'
  },
  sentry: {
    dsn: 'https://fc1d871661c66e10889dcb9820a0b98a@o347068.ingest.us.sentry.io/4508455556743168'
  }
};