import {Component, Input} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {SearchDocumentComponent} from "../search-document/search-document.component";
import {ElasticsearchService} from "../../../services/elasticsearch.service";

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  standalone: true,
  imports: [
    NgForOf,
    SearchDocumentComponent,
    NgIf,
    NgClass
  ],
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {
  @Input('documents') documents: any[] = [];
  @Input('perPage') perPage: number = 10;
  @Input('isLoading') isLoading: boolean = true;

  fakeDocument = {
    score: 0,
    name: "Exemple de document",
    webUrl: "https://exemple.com",
    nature_du_document: "Exemple",
    perimetre: "Siege",
    titre: "Rapport Annuel 2023",
    date_d_effet: "2023-12-01",
    description: "Résumé du rapport annuel.",
  };

  constructor(private elasticsearchService: ElasticsearchService) {
  }

  getPerPageArray(): number[] {
    return Array.from({ length: this.perPage }, (_, i) => i + 1);
  }

  protected readonly Array = Array;
}
